import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"

import GlobalStyles from "../components/GlobalStyles"
import Transition from "../components/Transition"

export default props => {
  const pages = require("../util/pages")

  // Site takedown - due to no 2nd year hosting payment.
  return (
    <p
      css={css`
        font-family: monospace;
      `}
    >
      Not Found
    </p>
  )

  return (
    <>
      <GlobalStyles />

      <div
        css={css`
          width: 100vw;
          margin-top: 1.5rem;

          display: grid;
          grid-template-rows: max-content 1fr 3rem;
          grid-template-columns: minmax(1rem, 1fr) minmax(0, 68rem) minmax(1rem, 1fr);
          grid-template-areas:  ". header ."
                                ". main ."
                                ". . .";
          grid-row-gap: ${props.lessGap ? "1rem" : "2rem"}; /* for moving homepage image closer to header text */
        `}
      >
        <header
          css={css`
            grid-area: header;

            display: grid;
            grid-template-rows: max-content max-content;
            grid-template-columns: 1fr;
            grid-template-areas: "nav" "text";
            grid-row-gap: ${props.lessGap ? "1.5rem" : "3rem"};
          `}
        >
          <div
            css={css`
              grid-area: text;
              align-self: end;
            `}
          >
            <Transition>
              <h1
                css={css`
                  font-family: "Barlow", sans-serif;
                  font-size: 2.5rem;
                  margin-bottom: .4rem;
                  text-align: center;
                  font-weight: 700;
                `}
              >
                {props.h1}
              </h1>

              {props.h2 &&
                <h2
                  css={css`
                    text-align: center;
                    color: var(--text-gray);
                  `}
                >
                  {props.h2}
                </h2>
              }
            </Transition>
          </div>

          <nav
            css={css`
              grid-area: nav;

              display: grid;
              grid-template-columns: repeat(${pages.length}, max-content);
              grid-column-gap: 4vw;
              margin: 0 auto;

              @media (max-width: 875px) {
                display: block;
              }
            `}
          >
            {pages.map(page => {
              // determines whether this link is for the current page,
              // in order to style that link differently
              const [isCurrent, setIsCurrent] = useState(false)

              useEffect(() => {
                setIsCurrent(window.location.pathname === page.path)
              }, [page.path])

              return (
                <Link
                  to={page.path}
                  key={page.name}
                  css={css`
                    text-decoration: none;
                    text-align: center;
                    font-size: .95rem;
                    border: 2px solid var(--text-superlight);
                    padding: 8px 12px;
                    line-height: 1;
                    border-radius: 4px;
                    font-family: "Barlow", sans-serif;
                    text-transform: uppercase;
                    font-weight: 500;
                    transition: background-color .2s, border-color .2s, color .2s;
                    position: relative;

                    @media (hover: hover) {
                      :hover {
                        background-color: var(--text-gray);
                        border-color: var(--text-gray);
                        color: white;
                      }
                    }

                    ${isCurrent && `
                      background-color: var(--text-gray);
                      border-color: var(--text-gray);
                      color: white;
                    `}

                    @media (max-width: 875px) {
                      border: none;
                      background: transparent;
                      color: var(--text-gray);
                      text-align: left;
                      display: inline-block;
                      padding: 0;
                      margin-right: 16px;
                      margin-bottom: 12px;

                      ${isCurrent && `
                        color: var(--text-black);

                        ::after {
                          content: "";
                          height: 2px;
                          width: 100%;
                          background-color: var(--text-superlight);
                          position: absolute;
                          left: 0;
                          bottom: -4px;
                        }
                      `}
                    }
                  `}
                >
                  {page.name}
                </Link>
              )
            })}
          </nav>
        </header>

        <main
          css={css`
            grid-area: main;
          `}
        >
          <Transition>
            {props.children}
          </Transition>
        </main>
      </div>
    </>
  )
}
