import React from "react"
import { css } from "@emotion/core"

import snarkdown from "snarkdown"

export default props => {
  const text = snarkdown(props.text)
    .replace(/<a/g, `<a target="_blank"`)
    .replace(/<img src="(.*?)" (.*?)>/g, `<a href="$1" target="_blank" rel="noopener noreferrer"><img src="$1" $2></a>`)

  return (
    <div
      css={css`
        font-size: 1.1rem;
        line-height: 1.7;
        color: var(--text-gray);

        * {
          font-size: inherit;
          line-height: inherit;
          color: inherit;
        }
        
        br {
          display: block;
          content: "";
          margin-top: 1rem;
        }

        p, pre, blockquote, ul, ol, pre {
          margin-bottom: 1rem;
        }

        strong {
          font-weight: 700;
        }

        h1, h2 {
          color: var(--text-white);
        }

        h1 {
          font-size: 1.7rem;
          font-weight: 700;
          margin-bottom: 1rem;
          margin-top: 2rem;
        }

        h2 {
          font-size: 1.3rem;
          font-weight: 600;
          margin-bottom: .5rem;
          margin-top: 1.5rem;
        }

        .code {
          font-family: "Menlo", monospace;
          font-size: .9rem;
        }

        blockquote {
          border-left: 2px solid hsl(0, 0%, 30%);
          padding-left: 1rem;
          font-style: italic;
        }

        ul, ol {
          margin-left: 2rem;
        }

        img {
          width: 512px;
          max-width: 100%;
          max-height: 90vh;
          margin: 32px auto;
          display: block;
        }
      `}
      dangerouslySetInnerHTML={{__html: text}}
    />
  )
}