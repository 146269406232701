import React from "react"
import { Helmet } from "react-helmet-async"

export default props => {
  const basics = require("../cms/basics.json")

  const title = props.title ? props.title : basics.site_name
  const ogTitle = props.title ? props.title + " - " + basics.site_name : basics.site_name
  const description = `${basics.subtitle} ${basics.homepage_caption}`

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />

      <meta property="og:site_name" content={basics.site_name} />
      <meta property="og:title" content={ogTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={"https://eastsideultimate.com" + basics.homepage_photo} />

      <meta name="twitter:card" content="summary_large_image" />

      <link rel="shortcut icon" href={"https://emojicdn.elk.sh/" + basics.emoji} />

      <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Barlow:500,700&display=swap" />
    </Helmet>
  )
}