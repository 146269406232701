module.exports = [
  {
    name: "Home",
    path: "/"
  },
  {
    name: "About",
    path: "/about"
  },
  {
    name: "Our Mission",
    path: "/mission"
  },
  {
    name: "Itinerary",
    path: "/itinerary"
  },
  {
    name: "Gallery",
    path: "/gallery"
  },
  {
    name: "Register",
    path: "/register"
  },
  {
    name: "Contact",
    path: "/contact"
  }
]
